<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Aws from "@/services/Aws";
    import TabTargetGroupInstances from "@/components/tabs/monitoring/tabTargetGroupInstances";
    import ModalRegisterInstanceToTargetGroup from "@/components/modals/monitor/aws/modalRegisterInstanceToTargetGroup";

    /**
     * Target Group Details component
     */
    export default {
        components: {
            Layout,
            PageHeader,
            TabTargetGroupInstances,
            ModalRegisterInstanceToTargetGroup
        },
        page: {
            title: "AWS Target Group",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "AWS Target Group",
                items: [
                    {
                        text: "AWS Target Groups",
                    },
                    {
                        text: "Target Group Details",
                        active: true,
                    },
                ],
                targetGroupData: [],
                registeredTargetGroupInstances: [],
                error: null,
                modalData: {},
                toggleProfile: false,
                showLoader: false,
                responseTimeWidget:'',
                requestCountWidget:'',
                loadBalancerArn:'',
                chart1Loader: false, // Loader for Chart 1
                chart2Loader: false, // Loader for Chart 2
                chartRecurringPeriod: "", // Selected refresh period in seconds
                refreshInterval: null, // Holds the interval ID
                progress: 0, // Progress percentage for the progress bar
                timeRemaining: 0, // Remaining time until the next action
                dataRecurringPeriod: "",
            };
        },
        async mounted() {
            this.getTargetGroupDetails()
        },
        methods: {

            getTargetGroupDetails() {

                this.showLoader = true;
                Aws.getTargetGroupDetails(this.$route.params.id).then((response) => {
                    this.targetGroupData = response.data.data;

                    if(this.targetGroupData.LoadBalancerArns[0]){
                        this.loadBalancerArn = this.targetGroupData.LoadBalancerArns[0]
                    }
                    this.targetGroupData.LoadBalancerNames

                    this.targetGroupCharts()
                }).catch((error) => {
                this.error = error.response.data.error
                    ? error.response.data.error
                    : "";
                }).finally(() => {
                    this.showLoader = false;
                });
            },

            toggle() {
                this.toggleProfile = !this.toggleProfile;
            },

            callModalRegisterInstance() {
                this.$bvModal.show("registerInstanceToTargetGroup_edit");
            },

            setRegisteredTargetGroupInstances(registeredInstances){
                this.registeredTargetGroupInstances = registeredInstances
            },

            targetGroupCharts(){
                this.chart1Loader = true
                this.chart2Loader = true
                Aws.targetGroupCharts(this.$route.params.id, this.loadBalancerArn).then((response) => {
                    this.responseTimeWidget= response.data.data.responseTimeWidget;
                    this.requestCountWidget= response.data.data.requestCountWidget;
                }).catch((error) => {
                    this.error = error.response.data.error
                        ? error.response.data.error
                        : "";
                }).finally(() => {
                    this.chart1Loader = false
                    this.chart2Loader = false
                });
            },

            dataRecurringRefresh() {
                if (this.refreshInterval) {
                    clearInterval(this.refreshInterval);
                    this.refreshInterval = null;
                }

                if (this.dataRecurringPeriod) {
                    const period = parseInt(this.dataRecurringPeriod, 10); // Refresh period in seconds
                    this.timeRemaining = period; // Reset the timer
                    this.progress = 0; // Reset progress

                    let isPaused = false; // Flag to pause execution

                    // Start a new interval for refreshing charts and updating the progress bar
                    this.refreshInterval = setInterval(() => {
                        if (isPaused) return; // Skip execution if paused

                        if (this.timeRemaining > 0) {
                            this.timeRemaining--;
                            this.progress = ((period - this.timeRemaining) / period) * 100;
                        } else {
                            // Call getApplications and pause for 2 seconds
                            this.targetGroupCharts();
                            isPaused = true;

                            setTimeout(() => {
                                this.timeRemaining = period; // Reset timer for the next interval
                                this.progress = 0; // Reset progress bar
                                isPaused = false; // Resume execution
                            }, 2000);
                        }
                    }, 1000);
                }
            },

        },
        beforeDestroy() {
            if (this.refreshInterval) {
                clearInterval(this.refreshInterval);
            }
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4" v-if="!this.error">
            <div v-if="!toggleProfile" class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body" v-if="!showLoader">
                        <div class="text-center">
                            <b-dropdown
                                    class="float-end"
                                    variant="white"
                                    right
                                    menu-class="dropdown-menu-end"
                                    toggle-class="font-size-16 text-body p-0"
                            >
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>
                                <a v-if="targetGroupData"
                                   href="javascript:void(0);"
                                          class="dropdown-item"
                                          title="Register Instance"
                                          @click="callModalRegisterInstance"
                                          :class="{ 'disabled': !targetGroupData.eligibleInstances || targetGroupData.eligibleInstances.length == 0 }"
                                >Register Instance</a>
                            </b-dropdown>
                            <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <i class="uil uil-refresh font-size-20 btn p-0 link-primary" @click="getTargetGroupDetails()" title="Refresh Data"></i>
                            <div class="clearfix"></div>

                            <h3 class="mt-3 mb-1" >
                                {{targetGroupData.TargetGroupName}}
                            </h3>
                            <small class="mt-3 mb-1">{{targetGroupData.TargetGroupArn}}</small>
                            <p class="mt-2 mb-1"></p>
                            <div class="mt-2">
                                <div class="font-size-18">

                                </div>
                            </div>
                        </div>

                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <h5>Target Group Information</h5>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Protocol :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{targetGroupData.Protocol}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Port :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" >{{targetGroupData.Port}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Target Type :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" >{{targetGroupData.TargetType}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Load Balancers :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" v-for="(loadBalancerName, index) in targetGroupData.LoadBalancerNames" :key="index"> {{loadBalancerName}} </span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Health Check Enabled :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{targetGroupData.HealthCheckEnabled}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Min Registered Instances :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{targetGroupData.minRegisteredInstances}}</span>
                                </p>
                            </div>

                            <hr />

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Notes :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{targetGroupData.note}}</span>
                                </p>
                            </div>

                            <b-button v-if="targetGroupData"
                                      class="mb-2 d-block m-auto mwb-11"
                                      variant="primary"
                                      title="Register Instance"
                                      @click="callModalRegisterInstance"
                                      :class="{ 'disabled': !targetGroupData.eligibleInstances || targetGroupData.eligibleInstances.length == 0 }"
                            ><i class="uil-desktop-cloud-alt"></i> Register Instance</b-button>

                        </div>
                    </div>
                    <div class="card-body d-flex align-items-center justify-content-center" v-else >
                        <b-spinner large></b-spinner>
                    </div>
                </div>
            </div>
            <div v-else class="col-xl-2">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="text-center">
                            <b-dropdown
                                    class="float-end"
                                    variant="white"
                                    right
                                    menu-class="dropdown-menu-end"
                                    toggle-class="font-size-16 text-body p-0"
                            >
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>
                                <a v-if="targetGroupData"
                                   href="javascript:void(0);"
                                   class="dropdown-item"
                                   title="Register Instance"
                                   @click="callModalRegisterInstance"
                                   :class="{ 'disabled': !targetGroupData.eligibleInstances || targetGroupData.eligibleInstances.length == 0 }"
                                >Register Instance</a>
                            </b-dropdown>
                            <i class="uil uil-angle-right float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <i class="uil uil-refresh font-size-20 btn p-0 link-primary" @click="getTargetGroupDetails()" title="Refresh Data"></i>
                            <div class="clearfix"></div>

                            <h5 class="mt-3 mb-1">
                                {{targetGroupData.TargetGroupName}}
                            </h5>
                            <small class="mt-3 mb-1">{{targetGroupData.TargetGroupArn}}</small>

                            <div class="mt-4">
                                <div class="font-size-18"></div>
                            </div>
                        </div>
                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <b-button v-if="targetGroupData"
                                      class="mb-2 d-block m-auto mwb-11"
                                      variant="primary"
                                      title="Register Instance"
                                      @click="callModalRegisterInstance"
                                      :class="{ 'disabled': !targetGroupData.eligibleInstances || targetGroupData.eligibleInstances.length == 0 }"
                            ><i class="uil-desktop-cloud-alt"></i> Register Instance</b-button>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="!toggleProfile ? 'col-xl-8' : 'col-xl-10'">
                <div class="card mb-0">
                    <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                        <b-tab active>
                            <template v-slot:title>
                                <i class="uil uil-list-ui-alt font-size-20"></i>
                                <span class="d-none d-sm-block">Target Group Instances</span>
                            </template>
                            <template v-if="!showLoader">
                                <TabTargetGroupInstances :TargetGroupArn="this.$route.params.id"
                                                         :minRegisteredInstances = "targetGroupData.minRegisteredInstances"
                                                         @setRegisteredTargetGroupInstances="setRegisteredTargetGroupInstances" />
                            </template>
                            <template v-else>
                                <div class="card-body d-flex align-items-center justify-content-center">
                                    <b-spinner large></b-spinner>
                                </div>
                            </template>
                        </b-tab>


                        <b-tab>
                            <template v-slot:title>
                                <i class="uil uil-chart-line font-size-20"></i>
                                <span class="d-none d-sm-block">Charts/Statistics</span>
                            </template>
                            <template>
                                <div class="row">
                                    <div class="col-6"></div>
                                    <div class="col-6 text-end">
                                        <template>
                                            <div>
                                                <div class="input-group mb-1">
                                                    <button type="button" class="btn btn-primary" @click="targetGroupCharts">
                                                        Refresh Charts
                                                    </button>
                                                    <select class="form-select" v-model="dataRecurringPeriod" @change="dataRecurringRefresh">
                                                        <option value="" selected>Select Refresh Period</option>
                                                        <option value="30">30 Seconds</option>
                                                        <option value="60">1 Minute</option>
                                                        <option value="300">5 Minutes</option>
                                                    </select>
                                                </div>

                                                <!-- Progress Bar -->
                                                <div class="progress" v-if="dataRecurringPeriod">
                                                    <div
                                                            class="progress-bar progress-bar-striped progress-bar-animated"
                                                            role="progressbar"
                                                            :aria-valuenow="progress"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            :style="{ width: `${progress}%` }"
                                                    >
                                                        <!--{{ timeRemaining }}s-->
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="row">
                                <!-- Chart 1 -->
                                <div class="col-6 position-relative chart-container">
                                    <div :class="{ 'chart-overlay': chart1Loader }">
                                        <img v-if="responseTimeWidget" :src="responseTimeWidget" alt="Response Time Widget" class="img-fluid">
                                    </div>
                                    <div v-if="chart1Loader" class="spinner-overlay">
                                        <b-spinner large></b-spinner>
                                    </div>
                                </div>

                                <!-- Chart 2 -->
                                <div class="col-6 position-relative chart-container">
                                    <div :class="{ 'chart-overlay': chart2Loader }">
                                        <img v-if="requestCountWidget" :src="requestCountWidget" alt="Request Count Widget" class="img-fluid">
                                    </div>
                                    <div v-if="chart2Loader" class="spinner-overlay">
                                        <b-spinner large></b-spinner>
                                    </div>
                                </div>
                            </div>
                            </template>
                        </b-tab>

                    </b-tabs>
                </div>
            </div>
        </div>
        <div v-else class="text-center mt-5">
            <div class="row">
                <div class="col-md-12">
                    <h4>{{this.error}}</h4>
                </div>
            </div>
        </div>
        <!-- end row -->

        <!-- MODALS -->
        <ModalRegisterInstanceToTargetGroup
                :eligibleInstances=targetGroupData.eligibleInstances
                :registeredInstances=registeredTargetGroupInstances
                :targetGroupName=targetGroupData.TargetGroupName
                @onRefresh="getTargetGroupDetails()"
        ></ModalRegisterInstanceToTargetGroup>
        <!-- END  MODALS -->
    </Layout>
</template>